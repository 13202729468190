<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="订单确认" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="padding-top:50px;background: #fff;">
      <div class="h1">
        <van-icon class="middle" name="manager-o" />
        <span class="middle" style="margin-left: 10px;" v-if="user">{{user.realName}}</span>
        <span class="middle" style="margin-left: 15px;" v-if="user">{{user.phone}}</span>
        <!-- <van-icon class="middle right" name="arrow" /> -->
      </div>
      <img class="jutiao" src="../../assets/img/jutiao.png" alt />
      <div class="dian">
        <div class="dian_a">
          <img class="left mendian" v-if="shangjia.doorPic" :src="shangjia.doorPic" alt />
          <img class="left mendian" v-else src="../../assets/img/cmendian.jpg" alt />
          <div class="left bao_h2">
            <div class="biaoti">{{shangjia.name}}</div>
            <div class="biaoti_b" style="border-bottom: 1px solid #f5f5f5;    margin-top: 5px;">
              <span
                class="left dizhi"
              >{{shangjia.province}}{{shangjia.city}}{{shangjia.district}}{{shangjia.address}}</span>
              <span class="right">{{juli}}km</span>
            </div>
            <div class="biaoti_b">
              <span class="left">
                <span class="bao">保</span>质保一年
              </span>

              <span class="right">
                <span class="middle" style="margin-right: 3px;">说明</span>
                <van-icon
                  class="middle"
                  style="font-size: 14px;margin-top: 1px;"
                  name="question-o"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <van-row>
        <van-col span="5">{{list.paintType ==1?"高端漆":"标准漆"}}</van-col>
        <van-col span="9" style="font-weight: bold;color: #333;">共{{list.num}}个标准面</van-col>
        <van-col span="5">×{{list.paintPrice}}</van-col>
        <van-col span="5" style="color: #ee0a24">￥{{list.paintPrice * list.num}}</van-col>
      </van-row>
      <div class="fanshi">
        <span class="left">支付方式</span>
        <span class="right" style="font-weight: bold;">在线支付</span>
      </div>
      <!-- <van-coupon-cell :coupons="coupons" :chosen-coupon="chosenCoupon" @click="showList = true" /> -->
      <van-cell @click="showList = true" is-link title="优惠券" :value="yhvalue"></van-cell>
    </div>
    <div class="bot">
      <div class="left lse">
        <div>
          应付金额：
          <span v-if="!yhisok">
            <span class="jiner">
              <span class="jin_biao">￥</span>
              <span v-if="checked">{{list.orderTotal-(userInfo.score / 100).toFixed(2)}}</span>
              <span v-else>{{list.orderTotal.toFixed(2)}}</span>
            </span>
          </span>
          <span v-if="yhisok">
            <span class="jiner">
              <span class="jin_biao">￥</span>
              <em v-if="youhui.type == 1">
                <span
                  v-if="checked"
                >{{(list.orderTotal-(userInfo.score / 100)-youhui.valueDesc).toFixed(2)}}</span>
                <span v-else>{{(list.orderTotal-youhui.valueDesc).toFixed(2)}}</span>
              </em>
              <em v-if="youhui.type == 2">
                <span
                  v-if="checked"
                >{{(list.orderTotal-(userInfo.score / 100)) * (youhui.valueDesc/10).toFixed(2)}}</span>
                <span v-else>{{list.orderTotal* (youhui.valueDesc/10).toFixed(2)}}</span>
              </em>
              <em v-if="youhui.type == 3">
                <span
                  v-if="checked"
                >{{(list.orderTotal-(userInfo.score / 100) - list.fuwu).toFixed(2)}}</span>
                <span v-else>{{(list.orderTotal- list.fuwu).toFixed(2)}}</span>
              </em>
            </span>
          </span>
        </div>
        <div v-if="youhui.type == 1" style="color: red;">
          <span class="youhui_a">&#12288;已优惠：</span>
          <span class="jin_biao">￥</span>
          {{youhui.valueDesc}}
        </div>
        <div v-if="youhui.type == 2" style="color: red;">
          <span class="youhui_a">&#12288;已优惠：</span>
          <span class="jin_biao">￥</span>
          {{(list.orderTotal - (list.orderTotal* (youhui.valueDesc/10))).toFixed(2)}}
        </div>
        <div v-if="youhui.type == 3" style="color: red;">
          <span class="youhui_a">&#12288;已优惠：</span>
          <span class="jin_biao">￥</span>
          {{list.fuwu}}
        </div>
      </div>
      <div class="right">
        <van-button
          @click="tijiao"
          class="bott"
          color="linear-gradient(to right, #ff6034, #ee0a24)"
          round
          v-preventReClick
          type="info"
        >提交订单</van-button>
      </div>
    </div>
    <!-- 优惠券列表 -->
    <van-popup v-model="showList" round position="bottom" style="height: 90%; padding-top: 4px;">
      <van-coupon-list
        :show-exchange-bar="false"
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        :disabled-coupons="disabledCoupons"
        @change="onChange"
        @click="xuanzhe"
      />
    </van-popup>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import preventReClick from "../../assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入

export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: {},
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      user: JSON.parse(localStorage.getItem("userInfo")),
      list: JSON.parse(sessionStorage.getItem("penqi")),
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      checked: false,
      showList: false,
      juli: "0",
      radio: 1,
      yhisok: false,
      ccid: "",
      id: "",
      Aswitch: 1,
      row: "",
      status: "",
      chosenCoupon: -1,
      coupons: [],
      isWxMini:'',
      yhvalue: "",
      disabledCoupons: [],
      youhui: {}
    };
  },
  methods: {
    tijiao() {
      var _this = this;
      if (this.chexin.carNo == "" || this.chexin.carNo == null) {
        _this.$toast("请先设置车牌！");
        return;
      }
      const data = {
        areaDetail:
          this.shangjia.province +
          this.shangjia.city +
          this.shangjia.district +
          this.shangjia.address, //维修厂详细地址
        // attrImg: this.list.goods[0].attrImg, //商品/服务图片
        carModel: this.chexin.carBrand, //车型
        carNo: this.chexin.carNo, //车牌
        couponPrice: 0, //优惠券额度
        paintPrice: this.list.paintPrice, //油漆单价
        paintType: this.list.paintType, //油漆种类 0:标准 1:高级
        garageName: this.shangjia.name, //修理厂名称
        gid: this.shangjia.id, //修理厂id
        mallPaintingInfoDOList: this.list.mallPaintingInfoDOList, //商品列表
        // num: this.list.goods.lenght,
        orderPaySetting: 1, //支付环境 1:线上 2:线下
        orderTotal: this.list.orderTotal, //商品打折后总价
        payType: this.radio, //支付方式 1:微信 2:余额
        receiver: this.user.realName ? this.user.realName : "", //用户名称
        //  receiver: this.shangjia.legalPerson, //用户名称
        serviceName: "喷漆", //商品标题
        shippingTotal: 0, //运费总价
        tel: this.user.phone ? this.user.phone : "",
        // tel: this.shangjia.legalPersonPhone,
        uid: this.user.id,
        type: 8,
        couponDOList: this.yhisok ? this.youhui.couponDOList : [],
        positionJd: this.shangjia.positionJd,
        positionWd: this.shangjia.positionWd,
        useCouponStatus: this.yhisok ? 1 : 0 //是否使用优惠券 0:未使用 1:使用
        // useScoreTotal: 0 //使用总积分
      };

      userModel
        .penqiorder(data)
        .then(e => {
          if (e.code == 200) {
            // loading.clear();
            _this.id = e.data.id;
            _this.status = e.data.status;
            _this.row = e.data.status;

            if (e.data.orderTotal == 0) {
              _this.$toast("下单成功");
              setTimeout(() => {
                _this.$router.push({
                  path: "order_details",
                  query: {
                    id: e.data.id,
                    status: e.data.status,
                    row: e.data.status,
                    type: e.data.type
                  }
                });
              }, 800);
            } else {
              if (_this.radio == 1) {
                var lis = {
                  id: e.data.id,
                  orderNumber: e.data.orderNumber,
                  totalMoney: e.data.orderTotal,
                  type: 1,
                  gid: this.shangjia.id
                };
                _this.ccid = e.data.id;
                var isWxMini = window.__wxjs_environment === "miniprogram";
                console.log(isWxMini);
                if (isWxMini) {
                  lis.token = localStorage.getItem("Authorization");
                  lis.ccisok = 1; //1:普通订单，2秒杀订单
                  let payDataStr = JSON.stringify(lis);
                  wx.miniProgram.navigateTo({
                    url: "/pages/payputong/payputong?payDataStr=" + payDataStr // 微信小程序调用支付的页面
                  });
                } else {
                  userModel.pay(lis).then(res => {
                    if (res.code == 200) {
                      if (typeof WeixinJSBridge === "undefined") {
                        // 微信浏览器内置对象。参考微信官方文档
                        if (document.addEventListener) {
                          document.addEventListener(
                            "WeixinJSBridgeReady",
                            _this.onBridgeReady(res.data),
                            false
                          );
                        } else if (document.attachEvent) {
                          document.attachEvent(
                            "WeixinJSBridgeReady",
                            _this.onBridgeReady(res.data)
                          );
                          document.attachEvent(
                            "onWeixinJSBridgeReady",
                            _this.onBridgeReady(res.data)
                          );
                        }
                      } else {
                        console.log("准备调用微信支付");
                        _this.onBridgeReady(res.data);
                      }
                    }
                  });
                }
              }
            }
          }
        })
        .catch(() => loading.clear());
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("下单成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                vm.ccid +
                "&status=" +
                "1" +
                "&row=" +
                "1" +
                "&type=" +
                vm.Aswitch;
            }, 1000);
          } else {
            vm.$toast("下单失败");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                vm.ccid +
                "&status=" +
                "0" +
                "&row=" +
                "0" +
                "&type=" +
                vm.Aswitch +
                "&isok=" +
                true;
            }, 1000);
          }
        }
      );
    },

    onChange(index) {
      if (index != -1) {
        this.yhisok = true;
        this.youhui = this.coupons[index];
        if (this.youhui.type == 1) {
          this.yhvalue = "-￥" + this.youhui.valueDesc.toFixed(2);
        } else if (this.youhui.type == 2) {
          this.yhvalue = this.youhui.valueDesc + "折";
        } else if (this.youhui.type == 3) {
          this.yhvalue = "免服务金额";
        }
      } else {
        this.yhisok = false;
        this.youhui = {};
        if (this.coupons.length != 0) {
          this.yhvalue = this.coupons.length + "张可用";
        } else {
          this.yhvalue = "无可用";
        }
      }

      this.showList = false;
      this.chosenCoupon = index;
    },
    xuanzhe(index) {
      console.log(index);
    },
    //优惠券列表
    youhuilist() {
      var itemInfos = [];
      var lis = {
        item: "喷漆",
        amount: this.list.orderTotal
      };
      itemInfos.push(lis);

      let data = {
        customerId: this.user.customerId,
        // customerId: 205,
        gid: this.shangjia.id,
        itemInfos: itemInfos,
        orderMoney: this.list.orderTotal,
        type: 1
      };
      userModel
        .satisfy(data)
        .then(e => {
          e.data.forEach(item => {
            var time1 = item.createdDate.replace(/-/g, "/");
            var createdDate = new Date(time1) / 1000;
            var time2 = item.validityDate.replace(/-/g, "/");
            var validityDate = new Date(time2) / 1000;
            var unitDesc = "";
            if (item.type == 1) {
              unitDesc = "元";
            } else if (item.type == 2) {
              unitDesc = "折";
            }
            // if (item.type == 1) {
            if (item.useCill > this.list.orderTotal) {
              var lit = {
                available: item.id,
                discount: 0,
                condition: item.couponName,
                reason: "未满" + item.useCill + "元不可用",
                value: item.type == 3 ? "免费券" : item.discountPrice,
                name: item.couponName,
                startAt: createdDate,
                endAt: validityDate,
                valueDesc: item.type == 3 ? "免费" : item.discountPrice,
                unitDesc: "元",
                type: item.type
              };
              this.disabledCoupons.push(lit);
            } else {
              var lis = {
                available: item.id,
                discount: 0,
                condition: item.couponName,
                reason: "超出100元不可用",
                value: item.type == 3 ? "免费券" : item.discountPrice,
                name: item.couponName,
                startAt: createdDate,
                endAt: validityDate,
                valueDesc: item.type == 3 ? "免费" : item.discountPrice,
                unitDesc: unitDesc,
                type: item.type,
                couponDOList: [item]
              };
              this.coupons.push(lis);
            }
            // }
          });
          if (this.coupons.length != 0) {
            // this.yhvalue = this.coupons.length + "张可用";
            this.yhisok = true;
            this.youhui = this.coupons[0];
            if (this.youhui.type == 1) {
              this.yhvalue = "-￥" + this.youhui.valueDesc.toFixed(2);
            } else if (this.youhui.type == 2) {
              this.yhvalue = this.youhui.valueDesc + "折";
            } else if (this.youhui.type == 3) {
              this.yhvalue = "免服务金额";
            }
            this.chosenCoupon = 0;
          } else {
            this.yhvalue = "无可用";
          }

          console.log();
        })
        .catch(() => loading.clear());
    },
    onLoads() {},

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    this.youhuilist();
    if (JSON.parse(localStorage.getItem("JD")) != null) {
      var radLat1 = (this.wd * Math.PI) / 180.0;
      var radLat2 = (this.shangjia.positionWd * Math.PI) / 180.0;
      var a = radLat1 - radLat2;
      var b =
        (this.jd * Math.PI) / 180.0 -
        (this.shangjia.positionJd * Math.PI) / 180.0;
      var s =
        2 *
        Math.asin(
          Math.sqrt(
            Math.pow(Math.sin(a / 2), 2) +
              Math.cos(radLat1) *
                Math.cos(radLat2) *
                Math.pow(Math.sin(b / 2), 2)
          )
        );
      s = s * 6378.137; // EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      this.juli = s.toFixed(2);
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
.h1 {
  line-height: 40px;
  font-size: 14px;
  padding: 0 12px;
  overflow: hidden;
}
.van-coupon-list__empty p {
  text-align: center;
}
.jutiao {
  height: 4px;
  width: 100%;
  display: block;
}
.dian {
  border-top: 6px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
}
.bao_h2 {
  width: 75%;
  margin-left: 15px;
}
.biaoti {
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.biaoti_b {
  font-size: 12px;
  color: #666;
  overflow: hidden;
  line-height: 30px;
}
.mendian {
  width: 70px;
  height: 70px;
}
.bao {
  display: inline-block;
  background: rgb(189, 227, 250);

  padding: 0px 2px;
  line-height: 16px;
  color: #6495ed;
  border-radius: 3px;
  font-size: 11px;
  margin-right: 4px;
}
.dian_a {
  padding: 15px 12px 2px 12px;
  overflow: hidden;
}
.middle {
  vertical-align: middle;
}
.fanshi {
  line-height: 45px;
  font-size: 13px;
  padding: 0 12px;
  border-bottom: 1px solid #f5f5f5;
  overflow: hidden;
}
.van-row {
  font-size: 13px;
  line-height: 40px;
  border-bottom: 10px solid #f5f5f5;
  color: #666;
}
.dizhi {
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.van-col {
  text-align: center;
}
/* 底部 */
.bot {
  width: 100%;
  /* padding: 10px; */
  position: fixed;
  height: 60px;
  background: #fff;
  z-index: 9;
  bottom: 0;
}
.lse {
  /* line-height: 60px; */
  font-size: 12px;
  margin-left: 12px;
  margin-top: 10px;
}
.bott {
  height: 40px;
  width: 110px;
  margin-right: 12px;
  margin-top: 10px;
}
.jin_biao {
  font-size: 10px;
}
.jiner {
  color: red;
  font-weight: bold;
  font-size: 18px;
}
.youhui_a {
  color: #333;
}
</style>